"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.carServiceClearanceOptions = exports.onDutyTimeOptions = exports.readyToLeaveOptions = exports.acceptedTaskOptions = exports.DutyStatus = exports.Districts = exports.colorCodeMap = exports.ServiceTypes = exports.preferredContactLabel = void 0;
exports.preferredContactLabel = Object.freeze({
    sms: 'Tekstiviesti',
    email: 'Sähköposti',
    phone: 'Puhelin',
    innovoice: 'Innovoice',
    interhinaus: 'Interhinaus',
    falck: 'Falck/Redgo',
});
exports.ServiceTypes = Object.freeze([
    'trailers',
    'roadServices',
    'replacementCars',
    'repairShops',
]);
exports.colorCodeMap = Object.freeze({
    0: 'Vihreä',
    1: 'Keltainen',
    2: 'Punainen',
    3: 'Ei ole',
});
exports.Districts = Object.freeze([
    'Etelä-Pohjanmaa',
    'Etelä-Savo',
    'Helsingin seutu',
    'Häme',
    'Kaakkois-Suomi',
    'Kainuu',
    'Keski-Suomi',
    'Lappi',
    'Länsi-Lappi',
    'Pirkanmaa',
    'Pohjanmaa',
    'Pohjois-Karjala',
    'Pohjois-Pohjanmaa',
    'Pohjois-Savo',
    'Satakunta',
    'Uusimaa',
    'Varsinais-Suomi',
]);
var DutyStatus;
(function (DutyStatus) {
    DutyStatus["INACTIVE"] = "inactive";
    DutyStatus["ACTIVE"] = "active";
    DutyStatus["ON_DUTY"] = "onDuty";
})(DutyStatus || (exports.DutyStatus = DutyStatus = {}));
exports.acceptedTaskOptions = [
    'Rengasrikko',
    'Ajoneuvo ei käynnisty (akku loppu)',
    'Polttoaine loppu',
    'Uloslukitus',
];
exports.readyToLeaveOptions = Object.freeze([
    0, 20, 40, 60,
]);
exports.onDutyTimeOptions = Object.freeze(Array.from({ length: 14 }, (v, i) => i + 1));
exports.carServiceClearanceOptions = Object.freeze(['Sähköauto', 'Kaasuauto']);
